// Functions related to the navigation drop-down.
// frankly I don't know if we're using these "channels" correctly
// at all... they are for "app-like Javascript" which this
// certainly isn't. But is this more than a "Javascript sprinkle"?
// Who's to say?

  var collapseAllDropDowns = function() {
    var all_sl = document.querySelectorAll(".sub_links");
    all_sl.forEach( function (sl) {sl.style.display = ""; sl.style.visibility = "";} );
  };

  var resetTransition = function() {
    var all_sl = document.querySelectorAll(".sub_links");
    all_sl.forEach( function (sl) {sl.style.transition = "";} );
  };

  var expandDropDown = function(e) {
    //only continue if screen size is small/medium
    if(window.innerWidth >= 960) return;

    //only continue if e is triggered on ".main_title"
    if(!e.target.classList.contains("main_title")) return;

    //find sub_links and toggle display

    var sublinks = e.target.nextElementSibling;

    if(sublinks.style.display === "block") {
      sublinks.style.display = "";
      sublinks.style.visibility = "";
    }
    else {
      collapseAllDropDowns();
      sublinks.style.display = "block";
      sublinks.style.visibility = "visible";
      sublinks.style.transition = "none";
    }

    e.preventDefault();
  };

  var toggleMenu = function(e) {
    if(e.target.parentElement === null) return;
    if(e.target.id !== "menu-icon" && e.target.parentElement.id !== "menu-icon") return;
    var n = document.querySelector("nav");
    if(n.style.display === "flex") {
      n.style.display = "";
    } else {
      n.style.display = "flex";
    }
    e.preventDefault();
  };

  var screenSizeChanged = function(e) {
    //this may not always work, but it's OK because it's just trying to make
    //things work a little smoother when changing between dimensions.
    if(window.previousWidth < 960 && window.innerWidth >= 960) {
      collapseAllDropDowns();
      //wipe out "transition: none" after the transition must have finished
      setTimeout( resetTransition, 500);

      window.previousWidth = window.innerWidth;
    } else if(window.previousWidth >= 960 && window.innerWidth < 960) {
      window.previousWidth = window.innerWidth;
    }
  };

  export default function addNavigation (e) {
    //console.log("running addListenersForNav");
    //var nav = document.querySelector("nav");
    window.addEventListener("click", expandDropDown);

    //var menu = document.getElementById("menu-icon");
    window.addEventListener("click", toggleMenu);

    window.previousWidth = window.innerWidth;
    window.addEventListener("resize", screenSizeChanged);
  };
